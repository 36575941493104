import axios from "axios"
import jwtAuthService from "./jwtAuthService"
import history from "history.js"

let isRefreshing = false;
let failedQueue = [];
const getToken = async () => {
    return await localStorage.getItem("jwt_token")
}
const getRefreshToken = async () => await localStorage.getItem("refresh_token")

const baseUrl = process.env.REACT_APP_BASE_URL
const apiClient = axios.create({
    baseURL: baseUrl,
    withCredentials: true
});


apiClient.interceptors.request.use(async (config) => {

    config.headers = {
        Authorization: "Bearer " + await getToken(),
        'Access-Control-Allow-Origin': '*'
    };

    return config
})

apiClient.interceptors.response.use(response => {
    return response
},
    error => {
        const originalRequest = error.config;
        // Reject promise if usual error
        if (error.response?.status !== 401) {
            return Promise.reject(error);
        }
        else if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;
            getRefreshResponse(originalRequest);
        }
        else{
            return Promise.reject(error);
        }
    })
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const getRefreshResponse = async (originalRequest) => {
    let request = {
        method: 'post',
        url: `/Live/refresh-accesstoken`,
        data: {
            authToken: await getToken(),
            refreshToken: await getRefreshToken()
        }
    }
    return await apiClient(request).then((res) => {
        let { authToken, avatar, email, fullName, refreshToken, roles, customerId, customerName } = res.data.data
        let user = {
            avatar: avatar,
            email: email,
            fullName: fullName,
            role: roles,
            customerId: customerId,
            customerName: customerName
        }
        jwtAuthService.setSession(authToken)
        jwtAuthService.setRefreshToken(refreshToken)
        jwtAuthService.setUser(user)
        processQueue(null, authToken);
        originalRequest.headers['Authorization'] = 'Bearer ' + authToken;
        Promise.resolve(axios(originalRequest));
        //return errorReq.response.config.headers['Authorization'] = 'Bearer ' + authToken

    }).catch(err => {
        jwtAuthService.logout()
        history.push('/signin')
        processQueue(err, null);
        Promise.reject(err);
    }).then(() => {
        isRefreshing = false;
    });

}

export { apiClient }